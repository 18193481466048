<template>
  <button class="btn" :href="href">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    href: String
  }
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
</style>
