<template>
  <div class="service-times-presentations template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcLocationVisitsNotes }}</h1>
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-header>
    <!-- / Page Header -->

    <page-body class="mx-lg">
      <section class="section-1">
        <div class="bg-block p-4">
          <header class="mb-5">
            <h2>{{ translations.tcVisitsNotes }}</h2>
            <ToggleArrow v-if="toggle == true" @click="Section1 = !Section1" section="Section1" :status="Section1">
            </ToggleArrow>
          </header>
          <b-button v-if="Section1 && iCanSeeAny(secured_note_add_controls)" variant="primary"
            @click="visitStoreAndRedirect({})">{{ translations.tcAddVisitNote }}</b-button>
          <b-button v-if="Section1 && convertedNotes.length > 6" class="ml-3" variant="primary"
            @click="showAll = !showAll">
            <span v-if="!showAll">{{ translations.tcViewAllNotes }}</span>
            <span v-if="showAll">{{ translations.tcHideExtraNotes }}</span>
          </b-button>
          <div v-if="Section1 && translations.components" class="pres-dates d-flex flex-wrap">
            <div class="col col-4 mb-3" v-for="(note, index) in this.convertedNotes" :key="note.nte_key">
              <app-event :obj="note" :i18n="translations.components" v-if="index < 6 || showAll"
                @edit_requested="onEditClick($event)">
              </app-event>
            </div>
          </div>
        </div>
      </section>
    </page-body>
    <!-- / Page Body -->
    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import Event from '@/components/Event.vue'
import BackJump from '@/components/back-jump/BackJump.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import date_data from '@/json/date.json'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'location-visits',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      toggle: true,
      showAll: false,
      Section1: true,
      Section2: true,
      backJumpObj: {
        link: '#',
        location: '',
      },
      secured_note_add_controls: {
        add_scripture_placement_facility_notes_button: '20c7fa38-b88d-4f5c-8288-91286637ed82',
        add_aux_facility_note_button: '8f22b782-c2e8-4aa9-82dc-a23cda34ba04',
      },
    }
  },
  methods: {
    ...mapActions({
      loadLocationVisits: 'scriptureDistribution/loadLocationVisits',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedNote: 'user/setSelectedNote',
    }),
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.loadLocationVisits(this.userSelectedLocationKey),
      ]).then(() => {
        this.backJumpObj.location = this.location.org_name
        this.backJumpObj.link = '/programs/sd/location-profile/'
        this.setLoadingStatus(false)
      })
    },
    async visitStoreAndRedirect(data) {
      this.setSelectedNote(data)
      this.$router.push({ name: 'location-add-visit' })
    },
    onEditClick(data) {
      this.setSelectedNote(data)
      this.$router.push({ name: 'location-visit-details' })
    },
  },
  computed: {
    ...mapGetters({
      iCanSeeAny: 'user/iCanSeeAny',
      location: 'scriptureDistribution/locationDetail',
      locationVisits: 'scriptureDistribution/locationVisits',
      userId: 'user/userId',
      userSelectedLocationKey: 'user/userSelectedLocationKey',
      prefCulture: 'user/userPreferredCulture',
    }),
    months() {
      return date_data.months.map((dm) => dm.abbrlow)
    },
    convertedNotes() {
      return this.locationVisits.map((obj) => {
        let cDate = !!obj.contact_date ? new Date(obj.contact_date) : null

        let month = !!cDate ? this.months[cDate.getMonth()] : ''
        let day = !!cDate ? cDate.getDate() : ''
        let year = !!cDate ? cDate.getFullYear() : ''
        let fullDate = !!obj.contact_date ? obj.contact_date : ''

        let rObj = {
          nte_key: obj.nte_key,
          contact_date: obj.contact_date,
          nte_ctt_key: obj.nte_ctt_key,
          contact_type: obj.contact_type,
          nte_contact_by_ind_key: obj.nte_contact_by_ind_key,
          name: obj.name,
          sort_name: obj.sort_name,
          nte_note: obj.nte_note,
          month: month,
          day: day,
          year: year,
          fullDate: fullDate,
          editLink: true,
        }

        return rObj
      })
    },
  },
  async created() {
    if (!this.userSelectedLocationKey) {
      this.$router.push('/programs/sd/scripture-distribution')
    }
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('back-jump', 'event'),
      this.pageLoad(),
    ]).then((results) => {
      const translatedText = { ...results[1] }
      this.$set(this.translations, 'components', translatedText)
    })
  },

  components: {
    appEvent: Event,
    BackJump: BackJump,
    pageHeader: pageHeader,
    pageBody: pageBody,
    pageFooter: pageFooter,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';

.service-times-presentations {
  header h1 {
    padding-bottom: 40px;

    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }

  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;

    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;

      svg path {
        fill: #000;
      }
    }
  }

  section {
    margin-bottom: 40px;

    .p-4 {
      padding: 40px 30px 20px 30px !important;

      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }

    header {
      display: flex;

      h2 {
        flex: 1 1 auto;

        @include breakpoint(sm) {
          margin-bottom: 30px;
        }
      }

      .toggle-arrow {
        align-self: flex-start;
        margin-top: 5px;
      }
    }

    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .service-times,
  .pres-dates {
    margin-top: 35px;
  }

  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.event {
  margin-bottom: 0;
  border: 1px solid #e9e9e9;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

  h3 {
    color: $teal-800;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }
}
</style>
