<template>
  <footer class="page-footer container" :class="{}">
    <slot></slot>
  </footer>
  <!-- / Page Footer -->
</template>

<script>
export default {
  props: {
    classes: Array
  }
}
</script>

<style lang="scss">
</style>
